import { get, map, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from '@pledge-earth/web-components';
import {
  Button,
  BannerMessage,
  TextField,
  TextAreaField,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Label,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';

import { ImageUpload } from '../../components/UploadFile/ImageUpload';
import type {
  GetProjectCertificationQuery,
  ProjectCertification,
  S3Object,
  S3ObjectInput,
} from '../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertProjectCertificationDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';

export function ProjectCertificationEdit({
  closeModal,
  onSaved,
  certification = {} as ProjectCertification,
}: {
  closeModal: () => void;
  certification?: GetProjectCertificationQuery['project_certification'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [projectCertificationForm] = Form.useForm();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectCertificationId, setCreatedProjectCertificationId] =
    useState<string>();

  const [upsertProjectCertificationMutation, { loading, error }] = useMutation(
    UpsertProjectCertificationDocument,
  );

  const handleFormFinished = useCallback(
    async (values: ProjectCertification) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
      const { name, description, registry_link } = values;
      const media = logoObj ? [logoObj] : certification.media;

      const data: any = omit(
        {
          ...certification,
          name,
          description,
          registry_link,
          media: map(media, (o) => omit(o, ['__typename'])),
        },
        ['updated_date', '__typename'],
      );

      const result = await upsertProjectCertificationMutation({
        variables: {
          data,
        },
      });

      const projectCertificationId =
        result.data?.upsert_project_certification?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project certification has been updated',
        });
        onSaved();
      } else {
        setCreatedProjectCertificationId(projectCertificationId);
        setShowSuccess(true);
      }
    },
    [onSaved, certification, logoObj, upsertProjectCertificationMutation],
  );

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectCertificationDetailsPage = useCallback(() => {
    if (createdProjectCertificationId) {
      navigate(
        `/offsetting/project-certifications/${createdProjectCertificationId}`,
      );
    }
    closeModal();
  }, [createdProjectCertificationId, closeModal, navigate]);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {get(certification, 'id') ? (
            <FormattedMessage id="edit-project-certification.title" />
          ) : (
            <FormattedMessage id="project-certification.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {error ? (
          <BannerMessage variant="critical">
            <FormattedMessage id="project-certification.edit.error" />
          </BannerMessage>
        ) : null}
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Certification Added"
            actionButtonCTAText="View Project Certification"
            actionButtonCTA={redirectToProjectCertificationDetailsPage}
          />
        ) : null}
        {!showSuccess && (
          <Form
            id={formId}
            size="large"
            layout="vertical"
            hideRequiredMark={true}
            form={projectCertificationForm}
            onFinish={handleFormFinished}
            initialValues={certification}
            validateTrigger="onBlur"
          >
            <Form.Item
              name="logo"
              label={
                <Label elementType="span">
                  <FormattedMessage id="project-certification.edit.logo" />
                </Label>
              }
            >
              <ImageUpload
                title={formatMessage({
                  id: 'project-certification.edit.upload_logo',
                })}
                onUploadImage={handleLogoUpload}
                folder={S3FolderEnum.ProjectCertification}
                logo={certification?.media?.[0] as any}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'project-certification.edit.name.required',
                  }),
                },
              ]}
            >
              <TextField
                label={
                  <FormattedMessage id="project-certification.edit.name" />
                }
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item name="description">
              <TextAreaField
                label={
                  <FormattedMessage id="project-certification.edit.description" />
                }
              />
            </Form.Item>
            <Form.Item
              name="registry_link"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'project-certification.edit.registry_link.required',
                  }),
                },
                {
                  type: 'url',
                  message: formatMessage({
                    id: 'project-certification.edit.registry_link.url',
                  }),
                },
              ]}
            >
              <TextField
                label={
                  <FormattedMessage id="project-certification.edit.registry_link" />
                }
                size={Size.Loose}
              />
            </Form.Item>
          </Form>
        )}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
