import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Spin,
  // eslint-disable-next-line no-restricted-imports -- blocked by PPL combobox
  Select,
  Form,
} from '@pledge-earth/web-components';
import {
  TextField,
  Heading,
  ContextualHelp,
  Size,
  Label,
} from '@pledge-earth/product-language';

import type {
  Client,
  PortfolioDraftInput,
} from '../../../services/graphql/generated';

import './PortfolioEditSettings.scss';

interface SettingsInitialState {
  acl: string | null | undefined;
  accounting_code: string | null;
  max_commission_percentage: number;
}

export function PortfolioEditSettings({
  formId,
  updateState,
  clientList,
  loadingClients,
  portfolio,
}: {
  formId: string;
  updateState: (newPortfolioState: PortfolioDraftInput) => void;
  clientList: Pick<Client, 'id' | 'legal_name'>[] | undefined;
  loadingClients: boolean;
  portfolio: PortfolioDraftInput;
}) {
  const { formatMessage } = useIntl();

  const [portfolioSettingsForm] = Form.useForm();
  const initialPortfolioState: SettingsInitialState = {
    acl: portfolio.portfolio_draft.acl?.length
      ? portfolio.portfolio_draft.acl[0]
      : null,
    accounting_code:
      portfolio.portfolio_draft?.details?.accounting_code ?? null,
    max_commission_percentage: 20,
  };
  const clientsDropdownOptions = useMemo(() => {
    const dropdownOptions = clientList?.map((client) => (
      <Select.Option value={client.id} key={client.legal_name}>
        {client.legal_name}
      </Select.Option>
    ));
    dropdownOptions?.push(
      <Select.Option value={null} selected={true} key="All organisations">
        All organisations
      </Select.Option>,
    );
    return dropdownOptions;
  }, [clientList]);

  const updateAcl = useCallback(
    (client_id: any) => {
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          acl: client_id ? [client_id] : [],
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  const updateAccountingCode = useCallback(
    (value: string) => {
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          details: {
            ...portfolio.portfolio_draft.details,
            accounting_code: value,
          },
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  return (
    <div className="PortfolioEditSettings">
      <Spin spinning={loadingClients}>
        <Form
          id={formId}
          size="large"
          layout="vertical"
          hideRequiredMark={true}
          form={portfolioSettingsForm}
          initialValues={initialPortfolioState}
          validateTrigger="onBlur"
          className="PortfolioEditSettings__form"
        >
          <Heading level="h5">
            <FormattedMessage id="portfolio.edit.entitlements" />
          </Heading>
          <Form.Item
            name="acl"
            label={
              <Label elementType="span">
                <FormattedMessage id="portfolio.edit.acl" />
              </Label>
            }
          >
            <Select
              size="large"
              onSelect={updateAcl}
              defaultValue={null}
              showSearch={true}
              filterOption={(input, option) =>
                option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {clientsDropdownOptions}
            </Select>
          </Form.Item>
          <Heading level="h5">
            <FormattedMessage id="portfolio.edit.accounting" />
          </Heading>
          <Form.Item name="accounting_code">
            <TextField
              label={
                <>
                  <FormattedMessage id="portfolio.edit.accounting_code" />
                  <ContextualHelp className="PortfolioEditSettings__form__info-icon">
                    <FormattedMessage id="portfolio.edit.accounting_code.tooltip" />
                  </ContextualHelp>
                </>
              }
              size={Size.Loose}
              onChange={updateAccountingCode}
              placeholder={formatMessage({
                id: 'portfolio.edit.accounting_code.placeholder',
              })}
            />
          </Form.Item>
          <Form.Item
            name="max_commission_percentage"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'portfolio.edit.max_commission.required',
                }),
              },
            ]}
          >
            <TextField
              label={<FormattedMessage id="portfolio.edit.max_commission" />}
              size={Size.Loose}
              isDisabled={true}
              suffix="%"
            />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}
