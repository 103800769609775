import { get } from 'lodash';
import {
  ContextualHelp,
  NumberField,
  PlainButton,
  Strong,
  Text,
} from '@pledge-earth/product-language';
import { FormattedNumber } from 'react-intl';

import type { Inventory, Project } from '../../../services/graphql/generated';
import { ImageS3 } from '../../../components/Image/Image';

import './ProjectInventoryCard.scss';

export function ProjectInventoryCard({
  inventory,
  assigned_weight,
  update_assigned_weight = () => {},
  remove_allocation = () => {},
}: {
  inventory: Pick<
    Inventory,
    | 'id'
    | 'allocated_weight_kg'
    | 'weight_kg'
    | 'price_per_kg_in_gbp'
    | 'vintage'
  > & {
    project: Pick<Project, 'name' | 'media' | 'id'>;
  };
  assigned_weight: number;
  update_assigned_weight: (
    project_id: string,
    vintage: string,
    weight: number | null,
  ) => void;
  remove_allocation: (project_id: string, vintage: string) => void;
}) {
  const onWeightChange = (weight: number) => {
    update_assigned_weight(
      inventory.project.id,
      inventory.vintage,
      weight ?? 0,
    );
  };
  const maxWeight = Math.floor(
    (inventory.weight_kg - inventory.allocated_weight_kg) / 1000,
  );

  return (
    <div style={{ display: 'flex' }} className="ProjectInventoryCard">
      <div className="ProjectInventoryCard__image-container">
        <ImageS3
          s3Object={get(inventory.project.media, '0', {} as any)}
          ratio={1 / 1}
          className="ProjectInventoryCard__image-container__image"
        />
      </div>
      <div className="ProjectInventoryCard__details">
        <Strong>{inventory.project.name}</Strong>
        <Text variant="subdued">
          Avg.{' '}
          <FormattedNumber
            value={inventory.price_per_kg_in_gbp * 1000}
            style="currency"
            currency="GBP"
            currencyDisplay="code"
            maximumFractionDigits={2}
          />{' '}
          /t
        </Text>

        <NumberField
          label={
            <>
              Allocate inventory:
              <ContextualHelp>
                This will only be allocated to the portfolio once it is created
                and not when it is in draft
              </ContextualHelp>
            </>
          }
          description={
            <>
              <span className="font-medium">{maxWeight}t</span> available
            </>
          }
          action={
            <PlainButton
              variant="critical"
              onPress={() =>
                remove_allocation(inventory.project.id, inventory.vintage)
              }
            >
              Remove
            </PlainButton>
          }
          value={assigned_weight}
          onChange={onWeightChange}
          suffix="t"
          maxValue={maxWeight}
        />
      </div>
    </div>
  );
}
