import { get, map, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from '@pledge-earth/web-components';
import {
  Button,
  Text,
  BannerMessage,
  TextField,
  Checkbox,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Label,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';

import { ImageUpload } from '../../components/UploadFile/ImageUpload';
import type {
  GetRegistryQuery,
  Registry,
  S3Object,
  S3ObjectInput,
} from '../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertRegistryDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';

export function RegistryEdit({
  closeModal,
  onSaved,
  registry = {} as Registry,
}: {
  closeModal: () => void;
  registry?: GetRegistryQuery['registry'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [registryForm] = Form.useForm();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdRegistryId, setCreatedRegistryId] = useState<string>();

  const [upsertRegistryMutation, { loading, error }] = useMutation(
    UpsertRegistryDocument,
  );

  const handleFormFinished = useCallback(
    async (values: any) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
      const { name, website, has_automated_retirement } = values;
      const media = logoObj ? [logoObj] : registry.media;

      // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
      const registry_retirement_fee_per_tonne =
        values.registry_retirement_fee_per_tonne > 0
          ? Number(values.registry_retirement_fee_per_tonne)
          : 0;

      const data: any = omit(
        {
          ...registry,
          name,
          website,
          has_automated_retirement,
          registry_retirement_fee_per_tonne,
          media: map(media, (o) => omit(o, ['__typename'])),
        },
        ['public_id', 'updated_date', '__typename'],
      );

      const result = await upsertRegistryMutation({
        variables: {
          data,
        },
      });

      const registryId = result.data?.upsert_registry?.public_id;

      if (onSaved) {
        showSuccessToast({
          description: 'Registry has been updated',
        });
        onSaved();
      } else {
        setCreatedRegistryId(registryId);
        setShowSuccess(true);
      }
    },
    [onSaved, registry, logoObj, upsertRegistryMutation],
  );

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToRegistryDetailsPage = useCallback(() => {
    if (createdRegistryId) {
      navigate(`/offsetting/registries/${createdRegistryId}`);
    }
    closeModal();
  }, [createdRegistryId, closeModal, navigate]);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {get(registry, 'id') ? (
            <FormattedMessage id="edit-registry.title" />
          ) : (
            <FormattedMessage id="registry.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {error ? (
          <BannerMessage variant="critical">
            <FormattedMessage id="registry.edit.error" />
          </BannerMessage>
        ) : null}
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Registry Added"
            actionButtonCTAText="View Registry"
            actionButtonCTA={redirectToRegistryDetailsPage}
          />
        ) : null}
        {!showSuccess && (
          <Form
            id={formId}
            size="large"
            layout="vertical"
            hideRequiredMark={true}
            form={registryForm}
            onFinish={handleFormFinished}
            initialValues={registry}
            validateTrigger="onBlur"
          >
            <Form.Item
              name="logo"
              label={
                <Label elementType="span">
                  <FormattedMessage id="registry.edit.logo" />
                </Label>
              }
            >
              <ImageUpload
                title={formatMessage({
                  id: 'registry.edit.upload_logo',
                })}
                onUploadImage={handleLogoUpload}
                folder={S3FolderEnum.Registry}
                logo={registry?.media?.[0] as any}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'registry.edit.name.required',
                  }),
                },
              ]}
            >
              <TextField
                label={<FormattedMessage id="registry.edit.name" />}
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item
              name="website"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'registry.edit.website.required',
                  }),
                },
                {
                  pattern:
                    /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                  message:
                    'Website address is invalid, expected url in format https://example.com/optional/path',
                },
              ]}
            >
              <TextField
                label={<FormattedMessage id="registry.edit.website" />}
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item name="infrastructure">
              <TextField
                label={<FormattedMessage id="registry.edit.infrastructure" />}
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item name="registry_retirement_fee_per_tonne">
              <TextField
                label={
                  <FormattedMessage id="registry.edit.retirement_fee_per_tonne" />
                }
                defaultValue="0"
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item name="has_automated_retirement" valuePropName="checked">
              <Checkbox>
                <Text variant="subdued">
                  <FormattedMessage id="registry.edit.has-automated-retirement" />
                </Text>
              </Checkbox>
            </Form.Item>
          </Form>
        )}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
