import {
  ComboBoxField,
  OptionListItem,
  Size,
} from '@pledge-earth/product-language';
import { useState, type Key, type ReactNode } from 'react';

interface ComboBoxSearchProps {
  size?: Size.Loose | Size.Compact | Size.Default;
  placeholder: string;
  label?: ReactNode;
  items: { value: string; id: string }[];
  isLoading: boolean;
  onSelect?: (id: Key | null) => void;
}

export function ComboBoxSearch({
  size = Size.Default,
  placeholder,
  label,
  isLoading,
  items,
  onSelect,
}: ComboBoxSearchProps) {
  const [fieldState, setFieldState] = useState<{
    selectedKey: Key | null;
    inputValue: string;
  }>({
    selectedKey: null,
    inputValue: '',
  });

  const onSelectionChange = (id: Key | null) => {
    if (onSelect) {
      onSelect(id);
    }
    setFieldState({
      inputValue: items.find((o) => String(o.id) === id)?.value ?? '',
      selectedKey: id,
    });
  };

  const onInputChange = (value: string) => {
    setFieldState((prevState) => ({
      inputValue: value,
      selectedKey: value === '' ? null : prevState.selectedKey,
    }));
  };

  return (
    <ComboBoxField
      size={size}
      label={label}
      menuTrigger="focus"
      placeholder={placeholder}
      isLoading={isLoading}
      defaultItems={items}
      selectedKey={String(fieldState.selectedKey)}
      inputValue={fieldState.inputValue}
      onSelectionChange={onSelectionChange}
      onInputChange={onInputChange}
    >
      {(option: { value: string; id: string }) => (
        <OptionListItem textValue={option.value} key={option.value}>
          {option.value}
        </OptionListItem>
      )}
    </ComboBoxField>
  );
}
