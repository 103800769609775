import { get, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from '@pledge-earth/web-components';
import {
  Button,
  TextField,
  BannerMessage,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';

import type {
  GetProjectOwnerQuery,
  ProjectOwner,
} from '../../services/graphql/generated';
import { UpsertProjectOwnerDocument } from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';

export function ProjectOwnerEdit({
  closeModal,
  onSaved,
  projectOwner = {} as ProjectOwner,
}: {
  closeModal: () => void;
  projectOwner?: GetProjectOwnerQuery['project_owner'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [projectOwnerForm] = Form.useForm();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectOwnerId, setCreatedProjectOwnerId] = useState<string>();

  const [upsertProjectOwnerMutation, { loading, error }] = useMutation(
    UpsertProjectOwnerDocument,
  );

  const handleFormFinished = useCallback(
    async (values: any) => {
      const { name, description } = values;

      const data: any = omit(
        {
          ...projectOwner,
          name,
          description,
        },
        ['updated_date', '__typename'],
      );

      const result = await upsertProjectOwnerMutation({
        variables: {
          data,
        },
      });

      const projectOwnerId = result.data?.upsert_project_owner?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project owner has been updated',
        });
        onSaved();
      } else {
        setCreatedProjectOwnerId(projectOwnerId);
        setShowSuccess(true);
      }
    },
    [onSaved, projectOwner, upsertProjectOwnerMutation],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectOwnerDetailsPage = useCallback(() => {
    if (createdProjectOwnerId) {
      navigate(`/offsetting/project-owners/${createdProjectOwnerId}`);
    }
    closeModal();
  }, [createdProjectOwnerId, closeModal, navigate]);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {get(projectOwner, 'id') ? (
            <FormattedMessage id="project-owner.edit.title" />
          ) : (
            <FormattedMessage id="project-owner.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {error ? (
          <BannerMessage variant="critical">
            <FormattedMessage id="project-owner.edit.error" />
          </BannerMessage>
        ) : null}
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Owner Added"
            actionButtonCTAText="View Project Owner"
            actionButtonCTA={redirectToProjectOwnerDetailsPage}
          />
        ) : null}
        {!showSuccess && (
          <Form
            id={formId}
            size="large"
            layout="vertical"
            hideRequiredMark={true}
            form={projectOwnerForm}
            onFinish={handleFormFinished}
            initialValues={projectOwner}
            validateTrigger="onBlur"
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'project-owner.edit.name.required',
                  }),
                },
              ]}
            >
              <TextField
                label={<FormattedMessage id="project-owner.edit.name" />}
                size={Size.Loose}
              />
            </Form.Item>
            <Form.Item name="description">
              <TextField
                label={<FormattedMessage id="project-owner.edit.description" />}
                size={Size.Loose}
              />
            </Form.Item>
          </Form>
        )}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
