import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { UploadFile } from '@pledge-earth/web-components';
import { generateCDNUrl } from '@pledge-earth/web-components';
import {
  DropZone,
  FileArrowUpIcon,
  FileTrigger,
  Text,
  Shimmer,
  Card,
  TrashIcon,
  IconButton,
  Button,
  Size,
  FeaturedIcon,
} from '@pledge-earth/product-language';
import type { DropEvent, DropItem } from 'react-aria';
import { useMutation } from '@apollo/client';

import type {
  S3FolderEnum,
  S3Object,
  Maybe,
} from '../../services/graphql/generated';
import { UploadFileDocument } from '../../services/graphql/generated';
import { showErrorToast } from '../../utils/toast';

export function DragImageUpload({
  onUploadImage,
  folder,
  initialFileList,
  onRemoveImage,
}: {
  onUploadImage?: (file: S3Object) => void;
  folder: S3FolderEnum;
  initialFileList?: Maybe<S3Object[]>;
  onRemoveImage?: (file: UploadFile) => void;
}) {
  const { formatMessage } = useIntl();

  const mapS3ObjectsToUploadFileObjects = useMemo(
    () =>
      initialFileList?.map(
        (s3ObjectImage) =>
          ({
            uid: s3ObjectImage.key,
            name: (s3ObjectImage.key || '').split('/').pop() ?? 'default.png',
            status: 'done',
            url: generateCDNUrl({
              width: 32,
              height: 32,
              s3Object: s3ObjectImage as any,
            }),
          }) as UploadFile,
      ),
    [initialFileList],
  );

  const [imageList, setFileList] = useState<UploadFile[]>(
    mapS3ObjectsToUploadFileObjects!,
  );

  const requiredTypes = ['image/jpeg', 'image/png'];
  const requiredSize = 3145728; // 3mb max file size

  const [uploadFile, { loading }] = useMutation(UploadFileDocument, {});

  const handleBeforeUpload = (file: File): boolean => {
    const isJpgOrPng = requiredTypes.includes(file.type);
    if (!isJpgOrPng) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.format.error' }),
      });
      return false;
    }

    const isAllowedSize = file.size < requiredSize;
    if (!isAllowedSize) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.size.error.3mb' }),
      });
      return false;
    }

    return true;
  };

  const handleUpload = async (file: File): Promise<void> => {
    try {
      const result = await uploadFile({
        variables: {
          file,
          folder,
        },
      });
      if (result.data?.upload_file) {
        onUploadImage?.(result.data.upload_file);
        setFileList((prev) => [
          ...prev,
          {
            uid: result.data?.upload_file.key,
            name: file.name,
            status: 'done',
            url: generateCDNUrl({
              width: 32,
              height: 32,
              s3Object: result.data?.upload_file as any,
            }),
          } as UploadFile,
        ]);
      }
    } catch (e) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.error' }),
      });
    }
  };

  const onDrop = (e: DropEvent) => {
    // Filter items that are files
    e.items.forEach(async (item: DropItem) => {
      if ('getFile' in item) {
        // Ensure the item has getFile method (i.e., it's a FileDropItem)
        const file = await item.getFile();
        if (file && handleBeforeUpload(file)) {
          await handleUpload(file);
        }
      }
    });
  };

  const onSelect = (files: FileList | null) => {
    Array.from(files ?? []).forEach(async (file) => {
      if (handleBeforeUpload(file)) {
        await handleUpload(file);
      }
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <DropZone onDrop={onDrop} className="h-[300px] w-full p-4 gap-4">
        {({ isDropTarget }) => (
          <>
            <FeaturedIcon size="40" shape="square">
              <FileArrowUpIcon />
            </FeaturedIcon>
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center flex-wrap">
                {isDropTarget ? (
                  <Text className="h-6 text-body-md font-medium">
                    <FormattedMessage id="add-project.file.drop_file" />
                  </Text>
                ) : (
                  <Text className="h-6">
                    <FormattedMessage
                      id="add-project.file.drop"
                      values={{
                        fileTrigger: (
                          <FileTrigger
                            allowsMultiple={false}
                            acceptedFileTypes={['.jpg', '.png']}
                            onSelect={onSelect}
                          >
                            <Button
                              className="-translate-y-px"
                              variant="default"
                              size={Size.Compact}
                            >
                              <FormattedMessage id="add-project.file.click" />
                            </Button>
                          </FileTrigger>
                        ),
                      }}
                    />{' '}
                  </Text>
                )}
              </div>
              <Text
                variant="subdued"
                size={Size.Compact}
                className="text-center"
              >
                <FormattedMessage id="add-project.file.max-size" />
              </Text>
            </div>
          </>
        )}
      </DropZone>
      {imageList?.length > 0 ? (
        <div className="flex flex-col w-full gap-2">
          {imageList.map((file) => (
            <Card
              key={file.uid}
              className="p-2 w-full flex flex-row justify-between items-center"
            >
              <div className="flex gap-2 items-center">
                <img
                  src={file.url}
                  alt={file.name}
                  className="w-8 h-8 rounded"
                />
                <span>{file.name}</span>
              </div>
              <IconButton
                variant="plain"
                type="button"
                label={formatMessage({ id: 'add-project.file.delete' })}
                onPress={() => {
                  onRemoveImage?.(file);
                  setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
                }}
              >
                <TrashIcon />
              </IconButton>
            </Card>
          ))}
          {loading ? <Shimmer className="w-full h-[50px]" /> : null}
        </div>
      ) : null}
    </div>
  );
}
