import { useCallback, useEffect, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from '@pledge-earth/web-components';
import {
  Button,
  Text,
  BannerMessage,
  TextAreaField,
  TextField,
  Size,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Dialog,
} from '@pledge-earth/product-language';
import type { S3Object } from '@pledge-earth/web-components';
import { useMutation } from '@apollo/client';

import { ImageUpload } from '../../UploadFile/ImageUpload';
import type {
  UpsertProjectDeveloperMutationVariables,
  S3ObjectInput,
  GetProjectDeveloperQuery,
} from '../../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertProjectDeveloperDocument,
} from '../../../services/graphql/generated';
import { EntityAdded } from '../EntityAdded/EntityAdded';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';

export function AddDeveloper({
  closeModal,
  developerDetails,
  onSave,
}: {
  closeModal: () => void;
  developerDetails?: GetProjectDeveloperQuery['project_developer'];
  onSave?: () => void;
}) {
  const navigate = useNavigate();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [developerAdded, setDeveloperAdded] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [createdDeveloperId, setCreatedDeveloperId] = useState<string>();
  const { formatMessage } = useIntl();
  const [
    upsertProjectDeveloperMutation,
    {
      loading: upsertProjectDeveloperLoading,
      data: upsertProjectDeveloperSuccess,
      error: upsertProjectDeveloperFailure,
    },
  ] = useMutation(UpsertProjectDeveloperDocument);

  useEffect(() => {
    if (upsertProjectDeveloperSuccess) {
      setDeveloperAdded(true);
      if (onSave !== undefined) {
        onSave();
      }
      showSuccessToast({
        description: 'Project developer has been updated',
      });
    } else if (upsertProjectDeveloperFailure) {
      showErrorToast({
        description: `Project developer has not been updated ...${upsertProjectDeveloperFailure.graphQLErrors[0]?.message}`,
      });
    }
  }, [upsertProjectDeveloperSuccess, upsertProjectDeveloperFailure, onSave]);

  const formId = useId();
  const [addDeveloperForm] = Form.useForm();

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  useEffect(() => {
    addDeveloperForm.resetFields();
    if ((developerDetails as any)?.logo) {
      setLogoObj((developerDetails as any).logo);
    }
  }, [developerDetails, addDeveloperForm]);

  const submitAddDeveloper = useCallback(
    async (inputData: {
      name: string;
      description: string;
      website: string;
    }) => {
      const payload: UpsertProjectDeveloperMutationVariables['data'] = {
        id: developerDetails?.id ?? undefined,
        name: inputData.name,
        description: inputData.description,
        website: inputData.website,
      };
      payload.logo = logoObj;
      const result = await upsertProjectDeveloperMutation({
        variables: {
          data: payload,
        },
      });

      const projectId = result.data?.upsert_project_developer.id;
      setCreatedDeveloperId(projectId);
    },
    [upsertProjectDeveloperMutation, logoObj, developerDetails],
  );

  const redirectToDeveloperDetailsPage = useCallback(() => {
    if (createdDeveloperId) {
      navigate(`/offsetting/projects/${createdDeveloperId}`);
    }
    closeModal();
  }, [createdDeveloperId, closeModal, navigate]);

  const onFinishFailed = useCallback(() => {
    setShowErrorBanner(true);
  }, []);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {developerDetails ? (
            <FormattedMessage id="edit-developer.title" />
          ) : (
            <FormattedMessage id="add-developer.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {developerAdded ? (
          <EntityAdded
            closeModal={closeModal}
            entityAddedTitle={formatMessage({
              id: 'add-developer.developer_saved',
            })}
            actionButtonCTAText={formatMessage({
              id: 'add-developer.view_developer',
            })}
            actionButtonCTA={redirectToDeveloperDetailsPage}
          />
        ) : (
          <>
            {showErrorBanner ? (
              <BannerMessage variant="critical">
                <Text>
                  <FormattedMessage id="add-developer.form_not_complete.title" />
                </Text>
              </BannerMessage>
            ) : null}
            <Form
              id={formId}
              layout="vertical"
              hideRequiredMark={true}
              form={addDeveloperForm}
              size="large"
              onFinish={submitAddDeveloper}
              onFinishFailed={onFinishFailed}
              initialValues={developerDetails}
            >
              <Form.Item name="logo">
                <ImageUpload
                  title={formatMessage({
                    id: 'add-developer.upload_logo',
                  })}
                  onUploadImage={handleLogoUpload}
                  folder={S3FolderEnum.ProjectDeveloper}
                  logo={(developerDetails as any)?.media?.[0]}
                />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'add-developer.name.message.required',
                    }),
                  },
                ]}
              >
                <TextField
                  label={<FormattedMessage id="add-developer.name" />}
                  size={Size.Loose}
                  placeholder={formatMessage({
                    id: 'add-developer.name.placeholder',
                  })}
                />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'add-developer.description.message.required',
                    }),
                  },
                ]}
              >
                <TextAreaField
                  label={<FormattedMessage id="add-developer.description" />}
                  placeholder={formatMessage({
                    id: 'add-developer.description.placeholder',
                  })}
                />
              </Form.Item>
              <Form.Item
                name="website"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'add-developer.website.message.required',
                    }),
                  },
                ]}
              >
                <TextField
                  label={<FormattedMessage id="add-developer.website" />}
                  size={Size.Loose}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </DialogBody>

      {!developerAdded && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="add-developer.cancel_button" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={upsertProjectDeveloperLoading}
          >
            <FormattedMessage id="add-developer.add_button" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
